const Nurseries = () => import('../views/admin/nurseries/Nurseries.vue')
const NurseryForm = () => import('../views/admin/nurseries/NurseryForm.vue')
const NurseryCategories = () => import('../views/admin/nurseryCategories/NurseryCategories.vue')
const NurseryCategoryForm = () => import('../views/admin/nurseryCategories/NurseryCategoryForm.vue')

export default [
  {
    path: 'nurseries/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: NurseryForm
  },
  {
    path: 'nurseries',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: Nurseries
  },
  {
    path: 'nurseryCategories',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: NurseryCategories
  },
  {
    path: 'nurseryCategories/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: NurseryCategoryForm
  }
]
