export default function getUniqueRef (str, existingRefs = []) {
  if (!str) {
    var chars = 'abcdefghiklmnopqrstuvwxyz'
    var rnum = Math.floor(Math.random() * chars.length)
    str = chars.substring(rnum, rnum + 1)
  }
  str = str.toLowerCase().replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')

  let ref

  let abbr = '$' + str.split(' ').map(s => s[0]).join('')
  if (!existingRefs.includes(abbr)) return abbr
  str = str.substr(0, 1) + str.substr(1).replace(/a|e|i|o|u/g, '')

  for (let i = 1; i < 10; i += 2) {
    ref = '$' + str.substr(0, i)
    if (!existingRefs.includes(ref)) break
  }
  while (existingRefs.includes(ref)) {
    ref = '_' + ref
  }
  return ref
}
