import { extend } from 'vee-validate'
import {
  required, min, max, email, min_value, max_value, between, length, is
} from 'vee-validate/dist/rules'
import { StringOrNumber } from 'vee-validate/dist/types/types'

const nonZeroValidator = {
  message (field: string) {
    return field + ' value must be greater than zero.'
  },
  validate (value: any) {
    return value !== null && value !== undefined && !Number.isNaN(Number(value)) && value > 0
  }
}

const urlValidator = {
  validate (value: any): boolean {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)
    }
    return false
  }
}
const sumToLargeValidator = {
  validate (value, args): boolean {
    return Number(args['remaining']) > (0 - args['fuzzy'] || 0)
  },
  params: ['remaining', 'fuzzy']
}

const sumToSmallValidator = {
  validate (value, args): boolean {
    return Number(args['remaining']) < (0 + args['fuzzy'] || 0)
  },
  params: ['remaining', 'fuzzy']
}

const maxLengthValidate = {
  validate (value: StringOrNumber | StringOrNumber[], { maxLength }: Record<string, any>): boolean {
    if (value === null || value === undefined) {
      return maxLength >= 0
    }
    if (Array.isArray(value)) {
      return value.length < maxLength
    }
    return String(value).length <= maxLength
  },
  params: ['maxLength']
}

export const loadVee = () => {
  extend('required', required)
  extend('email', email)
  extend('between', between)
  extend('min', min)
  extend('max', max)
  extend('max_value', max_value)
  extend('min_value', min_value)
  extend('non_zero', nonZeroValidator)
  extend('url', urlValidator)
  extend('length', length)
  extend('max_length', maxLengthValidate)
  extend('sum_to_large', sumToLargeValidator)
  extend('sum_to_small', sumToSmallValidator)
  extend('is', is)
}
