<template>
  <div>
    <v-menu
      v-if="user"
      :nudge-bottom="57"
      bottom
      max-width="92%"
      offset-x
      nudge-right="4%"
    >
      <template #activator="{ on }">
        <v-btn
          color="white"
          class="user-button"
          :small="small"
          :style="!small && { width: '48px', height: '48px' }"
          fab
          elevation="2"
          v-on="on"
        >
          <v-icon
            color="onyx"
          >
            mdi-account
          </v-icon>
        </v-btn>
      </template>
      <v-card
        width="350"
        :elevation="0"
      >
        <v-list-item class="pb-1">
          <v-list-item-avatar color="metal">
            <v-icon color="white">
              mdi-account
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">
              <span>{{ user.name }}</span>
              <router-link
                v-if="user.isAdmin"
                class="caption shamrock--text ml-1"
                to="/admin"
              >
                Admin
              </router-link>
            </v-list-item-title>
            <v-list-item-subtitle
              class="subtitle-1"
              style="line-height: 1;"
            >
              {{ user.company }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn
            icon
            text
            color="shamrock"
            class="ml-n5"
            @click="editedUserInformation"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-list-item>
        <v-card-actions
          class="pt-0 d-flex flex-column"
        >
          <v-btn
            text
            color="shamrock"
            class="my-1 justify-start"
            block
            to="/projects"
            exact
          >
            <v-icon left>
              mdi-nature-people
            </v-icon>
            <span>My projects</span>
          </v-btn>
          <v-btn
            text
            color="shamrock"
            class="ml-0 my-1 justify-start"
            block
            href="https://climatepositivedesign.com/wp-content/uploads/2024/09/CPD_Pathfinder-3.0_User-Guide.pdf"
            target="_blank"
          >
            <v-icon left>
              mdi-help
            </v-icon>
            <span>Pathfinder 3.0 User Guide</span>
          </v-btn>
          <v-btn
            text
            color="shamrock"
            class="ml-0 my-1 justify-start"
            block
            href="https://climatepositivedesign.com/"
            target="_blank"
          >
            <v-icon left>
              mdi-web
            </v-icon>
            <span>climatepositivedesign.com</span>
          </v-btn>
          <v-btn
            v-if="user.isAdmin"
            text
            color="shamrock"
            class="ml-0 my-1 justify-start"
            block
            to="/admin"
          >
            <v-icon left>
              mdi-account
            </v-icon>
            <span>Admin zone</span>
          </v-btn>
          <v-btn
            text
            color="shamrock"
            class="mx-0 my-1 justify-start"
            block
            href="https://climatepositivedesign.com/donate"
            target="_blank"
          >
            <v-icon left>
              mdi-heart
            </v-icon>
            Donate
          </v-btn>
          <v-btn
            text
            color="shamrock"
            class="mx-0 my-1 justify-start"
            block
            @click="logoutUser()"
          >
            <v-icon left>
              mdi-logout
            </v-icon>
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="editAccountDialog"
      max-width="650px"
    >
      <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit, dirty }"
      >
        <v-form @submit.prevent="handleSubmit(submitEditedUser)">
          <v-card
            style="overflow-x: hidden;"
            class="mb-3"
          >
            <v-card-title class="d-flex justify-center my-5">
              Edit My Account
            </v-card-title>
            <v-card-text>
              <ValidationProvider
                v-slot="{ errors }"
                name="Name"
                rules="required"
              >
                <v-text-field
                  v-model="editedUser.name"
                  :error-messages="errors"
                  label="Name"
                />
              </ValidationProvider>
              <v-expand-transition>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="editedUser.country"
                    :items="countries"
                    hide-selected
                    item-text="name"
                    autocomplete="off"
                    placeholder="Country"
                    data-vv-name="country"
                    name="country"
                    :error-messages="errors"
                    return-object
                  />
                </ValidationProvider>
              </v-expand-transition>
              <ValidationProvider
                v-slot="{ errors }"
                name="Country"
                rules="required"
              >
                <v-text-field
                  v-model="editedUser.company"
                  :error-messages="errors"
                  label="Company"
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-actions class="mb-4">
              <v-row style="justify-content: space-evenly;">
                <v-btn
                  color="primary"
                  min-width="150px"
                  large
                  @click="editAccountDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  min-width="150px"
                  large
                  :disabled="!dirty"
                  type="submit"
                >
                  Save
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex'
import countries from '@/views/signIn/countries.json'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const { mapGetters: mapAuthGetters, mapActions: mapAuthActions } = createNamespacedHelpers('auth')

export default {
  name: 'UserSettings',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editAccountDialog: false,
      countries,
      editedUser: {
        name: '',
        country: { name: '' },
        company: ''
      }
    }
  },
  computed: {
    ...mapState(['page']),
    ...mapAuthGetters(['user']),
    colors () {
      let colors = {}
      switch (this.page.toolbar) {
        case 'dark':
          colors.bar = 'onyx white--text'
          colors.arrow = 'white'
          break
        case 'light':
          colors.bar = 'leaf primary--text'
          colors.arrow = 'primary'
          break
        default:
          colors.bar = 'white primary--text'
          colors.arrow = 'primary'
          break
      }
      return colors
    },
    onLogout () {
      return `${window.location.origin}`
    }
  },
  methods: {
    ...mapAuthActions(['logoutUser', 'updateUser']),
    editedUserInformation () {
      const { name, company, country } = this.user
      this.editedUser = { name, company, country }
      this.editAccountDialog = true
    },
    async submitEditedUser () {
      this.updateUser(this.editedUser)
      this.editAccountDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-bar {
  box-shadow: none !important;
}
.v-btn--active {
  font-weight: 600;
}
// .user-button {
//   width: 45px !important;
//   max-width: 45px !important;
//   min-height: 45px !important;
//   border-radius: 50% !important;
//   margin-right: 20px;
//   transform: translateY(-10px);
// }
</style>
