const StructuralDiversities = () => import('../views/admin/structuralDiversities/StructuralDiversities.vue')
const StructuralDiversityForm = () => import('../views/admin/structuralDiversities/StructuralDiversityForm.vue')
export default [
  {
    path: 'structuralDiversities/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: StructuralDiversityForm
  },
  {
    path: 'structuralDiversities',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: StructuralDiversities
  }
]
