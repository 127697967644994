export default {
  shamrock: '#00A25F',
  leaf: '#f5f6f5',
  metal: '#8a97a2',
  moss: '#00433C',
  onyx: '#202528',
  crimson: '#E31F26',
  primary: '#202528',
  secondary: '#f6f4f3',
  accent: '#f03a47',
  error: '#ef969f',
  info: '#eca72c',
  success: '#055a5b',
  warning: '#FFC107',
  text: '#fff',
  theme: '#202528',
  toolbar: '#202528',
  bg: '#c4c3cc',
  fg: '#fc647c',
  default: '#c4c3cc',
  saffron: '#F2BE28',
  north: '#a6d6be',
  central: '#54bf8b',
  south: '#01a05e',
  cpdblue: '#00ADEE'

}
