const IrrigationTypes = () => import('../views/admin/irrigationTypes/IrrigationTypes.vue')
const IrrigationTypeForm = () => import('../views/admin/irrigationTypes/IrrigationTypeForm.vue')
export default [
  {
    path: 'irrigationTypes/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: IrrigationTypeForm
  },
  {
    path: 'irrigationTypes',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: IrrigationTypes
  }
]
