import versionModule from './versions'
import router from '../router/index'
import cleanAxios from 'axios'

const projectSchema = {
  title: '',
  description: '',
  type: '',
  image: {
    content: '',
    thumbnail: ''
  },
  completionDate: null,
  systemOfMeasurement: '',
  smallUnits: 'mm',
  enrichedData: {
    biomes: Object,
    protectedAreas100km: Object,
    evapotranspiration: Object,
    precipitation: Object,
    precipitationImage: Object,
    heatImage: Object,
    heatSeverity: Object
  },
  enrichingData: false,
  existingBiodiversitySiteConditions: {},
  location: {
    area: null, // area always in m2, converted when in metric mode
    centroid: [null, null], // coordinates of centre point
    polygons: [ // area can be multiple polygons so array
      [ // each polygon is an array of nodes
        [null, null] // coordinates of node
      ]
    ],
    address: null,
    country: null,
    state: null
  },
  certifications: [],
  currentPhase: null,
  cpdVersions: [Number],
  isStudy: false
}

export default {
  namespaced: true,
  state: {
    project: null,
    newProject: {
      ...projectSchema,
      systemOfMeasurement: 'metric',
      enrichingData: false,
      completionDate: new Date()
    }
  },
  getters: {
    project: state => state.project,
    newProject: state => state.newProject,
    hasHeatSeverityStatistics: state => {
      return state.project?.enrichedData?.heatSeverity?.statistics !== undefined
    },
    isLocatedInHeatIsland: state => {
      return !!state.project?.enrichedData?.heatSeverity?.statistics?.find((stat) => stat.max > 3)
    },
    isEnrichingData (state) {
      return state.project.enrichingData
    },
    evapotranspiration (state) {
      return state.project.enrichedData?.evapotranspiration?.statistics?.[0].max
    },
    isVersion3 (state) {
      return state.project.cpdVersions.includes(3)
    },
    isCEJSTUnderserved (state) {
      return !!state.project.enrichedData?.cejst?.parsedResponse?.underserved
    },
    hasExistingNativeEcosystem (state) {
      return !!state.project.existingBiodiversitySiteConditions.ecosystems?.find((ecosystem) => ecosystem.active && ecosystem.area > 0)
    }
  },
  mutations: {
    updateNewProject (state, payload) {
      state.newProject = {
        ...state.newProject,
        ...payload
      }
    },
    resetNewProject (state) {
      state.newProject = {
        ...projectSchema,
        systemOfMeasurement: 'metric',
        completionDate: new Date()
      }
    },
    setProject (state, payload) {
      state.project = payload
    },
    toggleEditingProjectMetadata (state) {
      state.editingProjectMetadata = !state.editingProjectMetadata
    },
    setEnrichingData (state, payload) {
      state.project.enrichingData = payload
    }
  },
  actions: {
    async fetchProjectById ({ dispatch }, id) {
      return dispatch('fetchProject', { id })
    },
    async enrichProject ({ commit, dispatch, getters }, datasets) {
      try {
        commit('setEnrichingData', true)
        const res = await this._vm.$axios.put(`/projects/${getters.project._id}/enrichProject`, null, { params: { datasets: datasets || [] } })
        commit('setProject', { ...res.data, enrichingData: false })

        return res.data
      } catch (err) {
        dispatch('showSnackbar', {
          color: 'error',
          text: 'Whoops, unable to enrich project. Please get in touch if this error continues.'
        }, { root: true })
        throw err
      }
    },
    async fetchProject ({ commit, dispatch }, payload) {
      try {
        const res = await this._vm.$axios.get(`/projects/${payload.id}`)
        commit('setProject', res.data)
        commit('setSystemOfMeasurement', res.data.systemOfMeasurement, { root: true })
        return res.data
      } catch (err) {
        dispatch('showSnackbar', { color: 'error', text: 'Whoops, unable to load project. Please get in touch if this error continues.' }, { root: true })
        throw err
      }
    },
    async refreshProject ({ getters, dispatch }) {
      await dispatch('fetchProject', { id: getters.project._id })
    },
    async updateProject ({ getters, commit, dispatch }, payload) {
      try {
        await this._vm.$axios.put(`/projects/${getters.project._id}`, payload)
      } catch (err) {
        dispatch('showSnackbar', { color: 'error', text: `Error updating project ${Object.keys(payload)[0]}. Please refresh the page and try again.` }, { root: true })
        throw err
      }
      commit('setProject', {
        ...getters.project,
        ...payload
      })
    },
    updateProjectTitle: {
      root: true,
      async handler ({ dispatch }, payload) {
        dispatch('updateProject', { title: payload })
      }
    },
    async deleteProject ({ getters }) {
      const { project } = getters
      await this._vm.$axios.delete(`/projects/${project._id}`)
      router.push('/projects')
    },
    updateProjectSystemOfMeasurement ({ dispatch, commit }, systemOfMeasurement) {
      dispatch('updateProject', { systemOfMeasurement })
      commit('setSystemOfMeasurement', systemOfMeasurement, { root: true })
    },
    async predictProjectImage ({ dispatch, getters }) {
      const { project } = getters
      const { location } = project
      let query = project.title
      query += location.city ? `, ${location.city}` : ''
      query += `, ${location.country}`
      query += `, ${project.type}`
      query += `, landscapes -politics -political -war -protest`
      const { data: key } = await this._vm.$axios.get('/utility/get-azure-key')
      const res = await cleanAxios.get('https://api.bing.microsoft.com/v7.0/images/search', {
        headers: {
          'Ocp-Apim-Subscription-Key': key
        },
        params: {
          q: query,
          minWidth: '1920',
          imageType: 'Photo',
          safeSearch: 'Moderate'
        }
      })
      const match = res.data.value.findIndex(x => x.contentUrl === (project.image ? project.image.content : ''))
      const index = match + 1
      const content = res.data.value[index] ? res.data.value[index].contentUrl : res.data.value[0]
      dispatch('updateProject', { image: { content } })
    },
    async updateProjectLocation ({ dispatch, getters }, payload) {
      const { project } = getters
      await dispatch('updateProject', { location: payload })
      dispatch('enrichProject')
      if (!project.image || !project.image.content) {
        dispatch('predictProjectImage')
      }
    }
  },
  modules: {
    versions: versionModule
  }
}
