import router from '@/router'
import categoriesModule from './categories'

export default {
  namespaced: true,
  state: {
    versions: [],
    cpdVersions: [2],
    version: null,
    statsLoading: false,
    hasShrubs: true,
    formDisabled: true
  },
  getters: {
    versions: state => state.versions,
    version: state => state.version,
    cpdVersions: state => state.cpdVersions,
    statsLoading: state => state.statsLoading,
    formDisabled: state => state.formDisabled,
    biodiversity: state => state.version?.stats?.biodiversity || {},
    waterUse: state => state.version?.stats?.waterUse || {}
  },
  mutations: {
    setVersions (state, payload) {
      state.versions = payload
    },
    setVersion (state, payload) {
      state.version = payload
    },
    setHasShrubs (state, payload) {
      state.hasShrubs = payload
    },
    setStatsLoading (state, payload) {
      state.statsLoading = payload
    },
    setFormDisabled (state, payload) {
      state.formDisabled = payload
    },
    setVersionStats (state, { stats, attributes }) {
      state.version = {
        ...state.version,
        stats,
        attributes
      }
    }
  },
  actions: {
    isOnlyPreferedVersion () {

    },
    async fetchVersions ({ commit, dispatch, rootGetters }, payload) {
      const { id } = payload
      try {
        const res = await this._vm.$axios.get('/versions', {
          params: { projectId: id }
        })
        commit('setVersions', res.data)
      } catch (err) {
        const snackbarInfo = { color: 'warning', text: 'Unable to load project versions. Please try reloading page, or get in touch if this error continues.' }
        dispatch('showSnackbar', snackbarInfo, { root: true })
        throw err
      }
    },
    async createVersion ({ dispatch, rootGetters }, payload) {
      const { data: version } = await this._vm.$axios.post('/versions', { ...payload, project: rootGetters['projects/project']._id })
      dispatch('fetchVersions', { id: rootGetters['projects/project']._id })
      return version
    },
    async duplicateVersion ({ dispatch, rootGetters }, payload) {
      const { versionToDuplicate, title } = payload
      const { data: version } = await this._vm.$axios.post(`/versions/${versionToDuplicate}/duplicate`, { title })
      dispatch('fetchVersions', { id: rootGetters['projects/project']._id })
      return version
    },
    async fetchVersion ({ getters, commit, dispatch }, payload) {
      const { id } = payload
      try {
        const { data: { version, hasShrubs } } = await this._vm.$axios.get(`/versions/${id}`)
        commit('setVersion', version)
        commit('setHasShrubs', hasShrubs)
      } catch (err) {
        if (err.response.status === 404) {
          dispatch('showSnackbar', { color: 'warning', text: 'Version could not be found, redirecting to project page' }, { root: true })
        } else {
          dispatch('showSnackbar', { color: 'error', text: 'Error loading version, redirecting to project page' }, { root: true })
        }
        router.push(`/projects/${router.currentRoute.params.id}`)
        throw err
      }
    },
    async deleteVersion ({ dispatch, rootGetters }, payload) {
      const id = payload
      try {
        await this._vm.$axios.delete(`/versions/${id}`)
        dispatch('fetchVersions', { id: rootGetters['projects/project']._id })
      } catch (err) {
        dispatch('showSnackbar', { color: 'error', text: 'Something went wrong, unable to delete version. Please try again.' }, { root: true })
        throw err
      }
    }
  },
  modules: {
    categories: categoriesModule
  }
}
