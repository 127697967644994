const TransportationOptions = () => import('../views/admin/transportationOptions/TransportationOptions.vue')
const TransportationOptionForm = () => import('../views/admin/transportationOptions/TransportationOptionForm.vue')
const TransportationDistanceOptions = () => import('../views/admin/transportationDistanceOptions/TransportationDistanceOptions.vue')
const TransportationDistanceOptionForm = () => import('../views/admin/transportationDistanceOptions/TransportationDistanceOptionForm.vue')

export default [
  {
    path: 'transportationOptions/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: TransportationOptionForm
  },
  {
    path: 'transportationOptions',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: TransportationOptions
  },
  {
    path: 'transportationDistanceOptions',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: TransportationDistanceOptions
  },
  {
    path: 'transportationDistanceOptions/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: TransportationDistanceOptionForm
  }
]
