import axios from 'axios'
import router from '../router/index'
import * as Sentry from '@sentry/vue'

export default {
  namespaced: true,
  state: {
    user: null
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    updateNewUser (state, payload) {
      state.newUser = {
        ...state.newUser,
        ...payload
      }
    },
    setUser (state, payload) {
      state.user = payload
    }
  },
  actions: {
    async setUserAuthenticated ({ commit, getters }, userData) {
      commit('setUser', userData)
      commit('setUserId', userData._id, { root: true })

      this._vm.$io.setUserId(userData._id)
    },
    async registerNewUser ({ dispatch }, { email, password, name, company, country }) {
      try {
        await this._vm.$axios.post('/users', {
          email,
          password,
          name,
          company,
          country
        })
        await dispatch('loginUser', { email, password })
      } catch (error) {
        if (error.response.status === 409) {
          // if 409 (conflict), user already exists
          dispatch('showSnackbar', 'That email address has already been used by another account.', { root: true })
        } else {
          // otherwise unknown error
          dispatch('showSnackbar', 'Something went wrong. Please try again or get in touch.', { root: true })
        }
        throw error
      }
    },
    async loginUser ({ dispatch }, { email, password }) {
      const { data: user, ...res } = await axios.create({ baseURL: this._vm.$baseURL + '/api/' }).post('/session', {
        email,
        password
      })
      const token = res.headers.authorization
      localStorage.setItem('cpd-tk', token)
      Sentry.setUser({ email, id: user._id })

      this._vm.$axios.defaults.headers.common.authorization = `Bearer ${token}`
      await dispatch('setUserAuthenticated', user)
    },
    async sendPasswordResetEmail ({ dispatch }, { email }) {
      try {
        await this._vm.$axios.post('/users/send_password_reset_email', {
          email,
          domain: window.location.origin
        })
      } catch (error) {
        switch (error.response.status) {
          case 429: {
            return dispatch('showSnackbar', { color: 'error', text: 'Whoops, you\'ve tried to reset your password too many times. Please double check your junk, or get in touch.' }, { root: true })
          }
          default: {
            dispatch('showSnackbar', 'Whoops, something went wrong. Please try again or get in touch.', { root: true })
            throw error
          }
        }
      }
    },
    async setNoUser ({ commit }) {
      commit('setUser', null)
      commit('setUserId', null, { root: true })
    },
    async logoutUser ({ rootGetters, dispatch }) {
      await dispatch('setNoUser')
      localStorage.removeItem('cpd-tk')
      Sentry.configureScope(scope => scope.setUser(null))
      this._vm.$axios.defaults.headers.common.authorization = null
      if (router.currentRoute.name !== 'landing') {
        router.push({ name: 'landing' })
      }
    },
    async agreeToTerms ({ getters, commit }) {
      const termsAgreed = new Date()
      await this._vm.$axios.put(`/users/${getters.user._id}`, {
        termsAgreed
      })
      commit('setUser', {
        ...getters.user,
        termsAgreed
      })
    },
    async updateUser ({ getters, commit, dispatch }, userData) {
      try {
        const { data } = await this._vm.$axios.put(`/users/${getters.user._id}`, userData)
        commit('setUser', data)
      } catch (error) {
        const snackbarInfo = { color: 'error', text: 'Something went wrong. Please try again later.' }
        dispatch('showSnackbar', snackbarInfo, { root: true })
      }
    }
  }
}
