const Ecosystems = () => import('../views/admin/ecosystems/Ecosystems.vue')
const EcosystemForm = () => import('../views/admin/ecosystems/EcosystemForm.vue')
const EcosystemCategories = () => import('../views/admin/ecosystemCategories/EcosystemCategories.vue')
const EcosystemCategoryForm = () => import('../views/admin/ecosystemCategories/EcosystemCategoryForm.vue')

export default [
  {
    path: 'ecosystems/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: EcosystemForm
  },
  {
    path: 'ecosystems',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: Ecosystems
  },
  {
    path: 'ecosystemCategories',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: EcosystemCategories
  },
  {
    path: 'ecosystemCategories/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: EcosystemCategoryForm
  }
]
