import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')

export default {
  namespaced: true,
  state: {
    categories: {
      material: [],
      plant: [],
      operation: []
    }
  },
  getters: {
    versionCategories: state => state.categories,
    ...mapVersionGetters(['version'])
  },
  mutations: {
    updateCategories (state, { type, categories }) {
      state.categories[type] = categories
    }
  },
  actions: {
    async fetchCategories ({ commit }, { type, system, version }) {
      // payload must {'type', 'system'}
      const { data: categories } = await this._vm.$axios.get(`/versions/${version._id}/categories`, {
        params: { type, system }
      })

      // add installation of trees impact manually
      if (type === 'operation' && version.stats.other?.installationOfTreesImpactInTonnes > 0) {
        categories.push({
          name: 'Installation of new trees',
          meta: {
            installationOfTreesImpactInTonnes: Math.round(version.stats.other.installationOfTreesImpactInTonnes * 1000)
          }
        })
      }
      // installation of natural forest impact
      if (type === 'operation' && version.stats.other?.installationOfForestImpactInTonnes > 0) {
        categories.push({
          name: 'Installation of natural forest',
          meta: {
            installationOfForestImpactInTonnes: Math.round(version.stats.other.installationOfForestImpactInTonnes * 1000)
          }
        })
      }

      commit('updateCategories', { categories, type })
      return categories
    }
  }
}
