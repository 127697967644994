import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import SvgIcon from 'vue-svgicon'
import VueIntro from 'vue-introjs'
import HighchartsVue from 'highcharts-vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { loadVee } from './vee'
import 'intro.js/introjs.css'
import 'intro.js/themes/introjs-modern.css'

import { Manager } from 'socket.io-client'

import i18n from './i18n'

import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

Vue.use(VueIntro)

Vue.use(HighchartsVue)

let serverBaseUrl
let environment

if (window.location.hostname === '127.0.0.1') {
  // the hostname is required to be "localhost" in develop
  window.location.href = 'http://localhost:8080'
}

switch (window.location.hostname) {
  case 'localhost':
    serverBaseUrl = `http://localhost:3000`
    environment = 'development'
    break
  case 'beta.climatepositivedesign.com':
  case 'app.climatepositivedesign.com':
    serverBaseUrl = 'https://api.climatepositivedesign.com'
    environment = 'production'
    break
  default:
    serverBaseUrl = 'https://api-staging.climatepositivedesign.com'
    environment = 'staging'
}
Vue.prototype.$baseURL = serverBaseUrl

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: 'https://7271c17b0e64494c864736cf71ea2658@o268683.ingest.sentry.io/6034629',
    environment,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'app.climatepositivedesign.com', 'staging.climatepositivedesign.com', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment === 'development' ? 1.0 : 0.05,
    ignoreErrors: [
      /^Request failed with status code 401$/, // redirects to sign in page
      /Loading ([\w]+ )?chunk .+ failed/, // nothing we can do
      /Network Error/, // usually when no internet - shows snackbar message anyway

      // intro.js errors
      /^Cannot read properties of null \(reading 'style'\)$/,
      /^Cannot set properties of null \(setting 'className'\)$/,
      /^Cannot set property 'className' of null$/,
      /^Cannot read property 'style' of null$/,

      // ignore user prompted vue router navigation errors
      /^Navigation cancelled from .* to .* with a new navigation.$/,
      /^Avoided redundant navigation to current location.*$/,

      // weird safari bug (doesn't cause a problem)
      /^AbortError: Fetch is aborted$/
    ]
  })
  Sentry.setTag('application', 'vue-client')
}

class IO {
  manager = new Manager(serverBaseUrl)
  userId = null
  sockets = {}
  setUserId (userId) {
    this.userId = userId
  }
  socket (namespace) {
    if (this.sockets[namespace]) {
      return this.sockets[namespace]
    }
    if (!this.manager) {
      throw new Error("Can't create a socket before setting id token")
    }
    this.sockets[namespace] = this.manager.socket(namespace, {
      auth: {
        userId: this.userId
      }
    })
    return this.sockets[namespace]
  }
}

Vue.prototype.$io = new IO()

axios.defaults.baseURL = serverBaseUrl + '/api/'

Vue.prototype.$axios = axios.create()

export const getAxios = () => Vue.prototype.$axios

const token = localStorage.getItem('cpd-tk')
if (token) {
  Vue.prototype.$axios.defaults.headers.common.authorization = `Bearer ${token}`
}

Vue.prototype.$axios.interceptors.response.use(res => res, error => {
  if (error.response && error.response.status === 401) {
    router.replace(`/start?redirect=${window.location.pathname + window.location.search}`)
  }
  if (error.message === 'Network Error') {
    store.dispatch('showSnackbar', { color: 'error', text: `We're having trouble connecting to the Pathfinder. Please check your internet connection, or come back later.` })
  }
  return Promise.reject(error)
})

Vue.config.productionTip = false

Vue.use(SvgIcon, {
  tagName: 'svgicon'
})
Vue.use(VueCookies, { expires: '7d' })
loadVee()

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
