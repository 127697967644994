const PlantingTypes = () => import('../views/admin/plantingTypes/PlantingTypes.vue')
const PlantingTypeForm = () => import('../views/admin/plantingTypes/PlantingTypeForm.vue')
export default [
  {
    path: 'plantingTypes/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: PlantingTypeForm
  },
  {
    path: 'plantingTypes',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: PlantingTypes
  }
]
