import convert, { Convert, Unit } from 'convert-units'

export function convertImperialDensityToMetric (v: number): number {
  return v * 16.018
}

export function squareMetresToSquareFeet (v: number): number {
  return convert(v).from('m2').to('ft2')
}

export function squareFeetToSquareMetres (v: number): number {
  return convert(v).from('ft2').to('m2')
}

interface ConvertProjectSystemProps {
  v: number;
  project: {[key: string]: any};
  from: Unit;
  toMetric: Unit;
  toImperial: Unit;
}

export type ConvertReturnType = {
  value: number;
  unit?: ReturnType<InstanceType<typeof Convert>['describe']>;
}

export function convertFromProjectSystem ({ v, project, to, fromMetric, fromImperial }) {
  let fromUnit
  if (project['systemOfMeasurement'] === 'metric') {
    fromUnit = fromMetric
  } else {
    fromUnit = fromImperial
  }
  return convert(v).from(fromUnit).to(to)
}

export function convertProjectSystem ({ v, project, from, toMetric, toImperial }: ConvertProjectSystemProps): ConvertReturnType {
  return convertSystem({ v, systemOfMeasure: project['systemOfMeasurement'], from, toMetric, toImperial })
}

type ConvertSystemProps = {
  systemOfMeasure: string;
} & Pick<ConvertProjectSystemProps, 'v' | 'from' | 'toMetric' | 'toImperial'>

export function convertSystem ({ v, systemOfMeasure, from, toMetric, toImperial }: ConvertSystemProps): ConvertReturnType {
  let toUnit: Unit
  if (systemOfMeasure === 'metric') {
    toUnit = toMetric
  } else {
    toUnit = toImperial
  }
  return {
    value: isNaN(v) ? NaN : convert(v).from(from).to(toUnit),
    unit: convert().describe(toUnit)
  }
}
