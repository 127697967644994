<template>
  <div id="app">
    <!-- 1590879600000 timestamp (see below) = 31st May 2020 -->
    <v-app
      v-if="!user || user.termsAgreed || new Date(user.accountCreated).getTime() > 1590879600000"
      :style="bodyColors"
    >
      <ToolbarTop />
      <v-main>
        <router-view />
      </v-main>
      <v-snackbar
        :value="snackbar.content"
        :color="`white--text ${snackbar.color}`"
      >
        {{ snackbar.content }}
      </v-snackbar>
      <!-- <v-btn style="position: fixed; bottom: 30px; left: 5vh;" @click="$store.commit('toggleTooltips')">Toggle tooltips</v-btn> -->
    </v-app>
    <v-app v-else>
      <v-main class="onyx leaf--text">
        <v-container class="pt-10">
          <v-row>
            <v-col>
              <div class="display-1 mb-3">
                Terms of service update
              </div>
              <div class="title mb-5">
                We've made some changes to our terms that you'll need to read and agree to before continuing:
              </div>
              <v-divider />

              <v-card
                style="height: calc(100vh - 270px); overflow-y: auto;"
              >
                <v-card-text
                  class="pt-2 terms-text"
                  v-html="termsText"
                />
              </v-card>

              <v-divider />

              <v-card-actions class="px-0 mt-2">
                <v-spacer />
                <!-- <v-btn
                  color="secondary"
                  text
                >
                  I do not agree
                </v-btn> -->
                <v-btn
                  color="white shamrock--text"
                  @click="agreeToTerms()"
                >
                  I have read and agree to the terms above
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ToolbarTop from '@/components/application/ToolbarTop'
import termsText from '@/assets/terms.md'

export default {
  name: 'App',
  components: {
    ToolbarTop
  },
  data () {
    return {
      termsText
    }
  },
  computed: {
    ...mapState(['snackbar', 'page']),
    user () {
      return this.$store.state.auth.user
    },
    bodyColors () {
      switch (this.page.content) {
        case 'dark':
          return { backgroundColor: 'var(--v-onyx-base)', color: 'var(--v-white-base)' }
        case 'light':
          return { backgroundColor: 'var(--v-leaf-base)', color: 'var(--v-primary-base)' }
        default:
          return { backgroundColor: '#fff', color: 'var(--v-primary-base)' }
      }
    }
  },
  async created () {
    await this.$store.dispatch('fetchConfigs')
  },
  mounted () {
    this.setWinFromCurrentViewport()
    window.addEventListener('resize', this.setWinFromCurrentViewport)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setWinFromCurrentViewport)
  },
  methods: {
    ...mapMutations(['setWin']),
    setWinFromCurrentViewport () {
      this.setWin({
        width: window.innerWidth,
        height: window.innerHeight
      })
    },
    agreeToTerms () {
      this.$store.dispatch('auth/agreeToTerms')
    }
  }
}
</script>

<style lang="scss">
html, body {
  // overflow-y: auto !important;
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
  // overflow-x: hidden !important;
  .v-btn {
    // custom default styling for buttons
    text-transform:none !important;
    font-weight: 400;
  }
  a {
    text-decoration: none;
  }
  .introjs-tooltiptext {
    font-family: "Inter", "Roboto", sans-serif !important;
  }
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  // background-color: var(--v-leaf-base);
  // background: #fff;
  .theme--light.v-input input::placeholder, .theme--light.v-input textarea::placeholder {
    color: #888;
  }
  .v-card__title {
    word-break: normal !important;
  }
}
.cpd-tooltip {
    opacity: 0;
    transition: opacity 1s ease 0.25s;
    z-index: 3;
    position: absolute;
    pointer-events: none;
    background: rgba(97, 97, 97, 0.9);
    width: 300px;
    color: #fff;
    font-size: 13px;
    border-radius: 5px;
    padding: 7px 13px;
    &.right, &.left {
      top: 50%;
      transform: translateY(-50%);
      &.right {
        left: calc(100% + 10px);
      }
      &.left {
        right: calc(100% + 10px);
      }
    }
    &::after {
      content: "";
      position: absolute;
      right: 100%;
      width: 5px;
      height: 5px;
      top: 50%;
      transform: translateY(-50%);
      // draw arrow
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid rgba(97, 97, 97, 0.9);
    }
    &.show {
      opacity: 1;
    }
  }
</style>
