
const allListDefinitions = [
  ['allElementCategories', '/element_categories'],
  ['allIrrigationTypes', '/irrigation_types'],
  ['allStructuralDiversities', '/structural_diversities'],
  ['allMaterials', '/materials'],
  ['allMaterialUseCategories', '/material_use_categories'],
  ['allNurseries', '/nurseries'],
  ['allPlantingTypes', '/planting_types'],
  ['allEcosystems', '/ecosystems'],
  ['allEcosystemCategories', '/ecosystem_categories'],
  ['allTransportationOptions', '/transportation_options'],
  ['allTransportationDistanceOptions', '/transportation_distance_options']
]

export default {
  namespaced: true,
  state: {
    allElementCategories: [],
    allIrrigationTypes: [],
    allStructuralDiversities: [],
    allMaterials: [],
    allMaterialUseCategories: [],
    allNurseries: [],
    allPlantingTypes: [],
    allEcosystem: [],
    allEcosystemCategories: [],
    allTransportationOptions: [],
    allTransportationDistanceOptions: []
  },
  getters: {
    allElementCategories: state => state.allElementCategories,
    allIrrigationTypes: state => state.allIrrigationTypes,
    allStructuralDiversities: state => state.allStructuralDiversities,
    allMaterials: state => state.allMaterials,
    allMaterialUseCategories: state => state.allMaterialUseCategories,
    allNurseries: state => state.allNurseries,
    allPlantingTypes: state => state.allPlantingTypes,
    allEcosystem: state => state.allEcosystem,
    allEcosystemCategories: state => state.allEcosystemCategories,
    allTransportationOptions: state => state.allTransportationOptions,
    allTransportationDistanceOptions: state => state.allTransportationDistanceOptions
  },
  mutations: {
    updateLists (state, payload) {
      for (let key of Object.keys(payload)) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async fetchLists ({ commit }) {
      let allLists = {}
      const lists = await Promise.all(allListDefinitions.map((conf) => this._vm.$axios.get(conf[1])))
      lists.forEach((list, i) => {
        allLists[allListDefinitions[i][0]] = list.data
      })
      commit('updateLists', allLists)
    }
  }
}
